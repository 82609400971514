@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin user-select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin maskProps($size,$color,$position) {
  -webkit-mask-size: $size;
  mask-size: $size;
  background-color: $color;
  -webkit-mask-position: $position;
  mask-position: $position;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

@mixin repeat-text-sett {
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
}


@mixin keyframe($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }

  @-moz-keyframes #{$animation_name} {
    @content;
  }

  @-o-keyframes #{$animation_name} {
    @content;
  }

  @keyframes #{$animation_name} {
    @content;
  }
}

@mixin animation($delay, $duration, $animation,$count: 1, $direction: normal, $fillMode: forwards, $timing:ease) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: $fillMode;
  -webkit-animation-iteration-count: $count;
  -webkit-animation-direction: $direction;
  -webkit-animation-timing-function: $timing;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-iteration-count: $count;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: $fillMode;
  -moz-animation-direction: $direction;
  -moz-animation-timing-function: $timing;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-iteration-count: $count;
  animation-name: $animation;
  animation-fill-mode: $fillMode;
  animation-direction: $direction;
  animation-timing-function: $timing;
}

@mixin push-auto {
  margin: {
    left: auto;
    right: auto;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
  $padding: unquote(( $y / $x ) * 100 + '%');
  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}
