@import "src/common/styles/common";

.wrapperFields {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  display: none;
  z-index: 100;

  &.show {
    display: flex;
    opacity: 1;
  }
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: none;
  z-index: 100;

  &[data-active="false"] {
    [role="dialog"] {
      opacity: 0 !important;
    }

    z-index: 99;
    pointer-events: none !important;
  }

  &.fadeIn {
    @include animation(0s, 0.5s, fadeIn, 1);
  }

  &.fadeOut {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &.show {
    display: flex;

    [data-role="dialog"] {
      @include animation(0s, 0.5s, translate, 1);
    }
  }

  &.showViewer {
    display: flex;

    [data-role="viewer"] {
      display: flex;
      flex-direction: column;
      @include animation(0s, 0.5s, fadeIn, 1);
    }
  }
}


@include keyframe(translate) {
  0% {
    opacity: 0;
    margin: 0 auto auto auto;
  }
  100% {
    opacity: 1;
    margin: 60px auto auto auto;
  }
}

@include keyframe(fadeIn) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@include keyframe(fadeOut) {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}
