#notify_box {
  top: 10px;
  display: flex;
  position: fixed;
  width: 100%;
  pointer-events: none;
  justify-content: center;
  flex-direction: column;
  z-index: 1000;

  .notify-popup {
    opacity: 0;
    transition: 0.5s ease;
    padding: 10px 20px;
    width: 400px;
    margin: auto auto 4px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 8px 10px rgba(66, 82, 110, 0.2), 0 6px 30px rgba(66, 82, 110, 0.12), 0 16px 24px rgba(66, 82, 110, 0.24);

    &.show {
      opacity: 1;
    }

    &.type-success {
      .notify-text {
        color: green;
      }
    }

    &.type-load {
      .notify-text {
        color: darkblue;
      }
    }

    &.type-error {
      .notify-text {
        color: darkred;
      }
    }

    &.type-info {
      .notify-text {
        color: darkblue;
      }
    }

    &.type-warning {
      .notify-text {
        color: orangered;
      }
    }

    .notify-text {
      margin: auto 0;
      padding: 5px 0 5px 5px;
      background-repeat: no-repeat;
      background-position: 0 50%;
      background-size: contain;
      text-align: center;
    }
  }
}

.confirmBox {
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  opacity: 0;

  transition: 0.5s ease;

  &.show {
    opacity: 1;
  }

  .confirmPopup {
    min-width: 400px;
    max-width: 600px;
    background-color: white;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 8px 10px rgba(66, 82, 110, 0.2), 0 6px 30px rgba(66, 82, 110, 0.12), 0 16px 24px rgba(66, 82, 110, 0.24);

    .confirmText {
      text-align: center;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    .btnContainerModal {
      display: flex;
      justify-content: center;

      .btn {
        padding: 8px 8px;
        height: 32px;
        position: relative;
        cursor: pointer;
        outline: none;
        border: none;
        border-radius: 4px;
        overflow: hidden;
        opacity: 1;
        transition: opacity 0.5s ease;
        //width: 100px;
        font-weight: 500;
        text-align: center;

        &.blue {
          background-color: #4966a8;
          color: white;
          margin-right: 20px;
          transition: 0.2s opacity ease;

        }

        &.red {
          background-color: #dc3545;
          color: white;
          margin-right: 20px;
          transition: 0.2s opacity ease;

        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}


