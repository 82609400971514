@import "src/common/styles/common";

.anim {

  &.inherit {
    width: inherit;
    height: inherit;
  }

  &.fade {
    @include animation(0s, 1s, shows, 1, normal, none);
  }

  &.blink {
    @include animation(0s, 1s, blinkAnim, 1, normal, none);
    //opacity: 0;
  }

  &.expand {
    @include animation(0s, 2s, expandAnim, 1);
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }
}

@include keyframe(blinkAnim) {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: .5;
  }
}

@include keyframe(shows) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@include keyframe(expandAnim) {
  0% {
    max-height: 0;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    max-height: 600px;
    opacity: 1;
  }
}
