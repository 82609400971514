.gameContainer {
  height: 100%;
  position: relative;
  display: flex;

  .buttonsContainer {
    position: fixed;
    left: 10px;
    top: 10px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: end;
  }

  &.landscape {
    flex-direction: row;
  }

  &.portrait {
    flex-direction: column;
  }
}
