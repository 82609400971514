.findObject {
  position: absolute;
  background-color: rgba(106, 255, 0, 0.4);
  border: 2px dashed white;
  display: flex;
  justify-content: center;
  align-items: center;

  .btnDelete {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: white;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABJUlEQVR4nO3YT0sCQRyH8YeuUu0KG/RHUG8efAceetFBJ6noEKUI6iG1wHolxcIYg6wi67Qzs3w/IHga92F09zeCiIiIiMg/6QEPwKXDNRvAHXBLhYbADzAHLhysdwo8W2ueUJEMmJoPfj9yZ/KdeDRrfQNdKpYCY3MBC+DKQUQHT46JsSO+fEYUxSwPjMkjnkKKsGNGVsw1u50BLyFGFMWsdsQEH7GR7Ik534poE7hkK+YmxoiNJjAxF/4BvJr3n0CLyCTAmwnIX+uYdqJ2Ic06fLWSgjtXdD/2dM/tN5pnSHrA0z3Y0aTMvBVsTFpiAg5mfHc9xnuNyaxT4qLkKbFhzv7eTocZMHN41PUWM6zLnw99E+Miwo65BwYO1xQRERER4c8v6Th7MMbV15kAAAAASUVORK5CYII=");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .btnAdd {
    cursor: pointer;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: white;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAU0lEQVR4nO3TwQmAUAwFwfTfdLx7VtwvM5ACHktmAIDX7e2OtYbEKFKjSI0iNYrUKFKTK7If32MMGUUm/VvHWkNiFKlRpEaRGkVqFKn5TREAmBNcXKbvEbAKdz4AAAAASUVORK5CYII=");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
