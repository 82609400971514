.loadScreen {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(139, 196, 228);
  background: linear-gradient(0deg, rgba(139, 196, 228, 1) 0%, rgba(170, 218, 185, 1) 24%, rgba(202, 233, 211, 1) 49%, rgba(210, 241, 179, 1) 77%, rgba(194, 236, 152, 1) 100%);

  .title {
    font-size: 34px;
    font-weight: bolder;
    color: #059ff5;
    text-align: center;
  }

  .desc {
    font-size: 24px;
    font-weight: bolder;
    color: #63b246;
    text-align: center;
    margin-bottom: 12px;
  }
}
