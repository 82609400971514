.text {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 0;
  margin: 0;
}

.mark {
  background-color: transparent;
  font-weight: inherit;
  font-size: inherit;
}
