@import "src/common/styles/common";

.select {
  position: relative;
  display: flex;
  flex-direction: column;
  @include transition(opacity 0.5s ease);

  input {
    cursor: pointer;
    color: black;
    width: 100%;
    background: white;
    border: 1px solid #4966a8;
    height: 100%;
    order: 2;
    font-weight: 500;
    border-radius: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.withSearch {
    input {
      cursor: initial;
    }
  }

  &.withSearchIcon {
  }

  &.disabled {
    opacity: 0.5;

    * {
      pointer-events: none;
    }
  }

  label {
    font-weight: 700;
    color: #4966a8;
    @include transition(transform 0.3s ease, font-size 0.3s ease);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
    order: 1;
    @include user-select-none;

    &.top {
    }
  }

  input {
    &:focus + label {
      @include transition(transform 0.3s ease, font-size 0.3s ease);
    }
  }

  .iconWrapper {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .icon {
    pointer-events: none;
    position: absolute;
    bottom: 10px;

    &.search {
      right: initial;
    }

    &.spinner {
      div {
        transform-origin: 10px 10px;
        animation: spinner-anim 0.8s linear infinite;

        &:after {
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          left: 9px;
          width: 2px;
          height: 6px;
          border-radius: 20%;
          background: #63b246;
        }

        &:nth-child(1) {
          transform: rotate(0deg);
          animation-delay: -0.7s;
        }

        &:nth-child(2) {
          transform: rotate(45deg);
          animation-delay: -0.6s;
        }

        &:nth-child(3) {
          transform: rotate(90deg);
          animation-delay: -0.5s;
        }

        &:nth-child(4) {
          transform: rotate(135deg);
          animation-delay: -0.4s;
        }

        &:nth-child(5) {
          transform: rotate(180deg);
          animation-delay: -0.3s;
        }

        &:nth-child(6) {
          transform: rotate(225deg);
          animation-delay: -0.2s;
        }

        &:nth-child(7) {
          transform: rotate(270deg);
          animation-delay: -0.1s;
        }

        &:nth-child(8) {
          transform: rotate(315deg);
          animation-delay: 0s;
        }
      }
    }

    &.arrow {
      transform-origin: center;
      @include transition(transform 0.3s ease);
      transform: rotate(180deg);

      &.active {
        transform: rotate(0deg);
      }
    }
  }

  @keyframes spinner-anim {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  min-width: 100px;

  input {
    min-height: 50px;
    height: 50px;
    padding: 12px;
  }

  label {
    font-size: 14px;
    margin-bottom: 4px;
  }

  //&.clear {
  //  input {
  //    padding-right: map_get($selectsSpacingIfIconLeft, $name);
  //  }
  //
  //  label {
  //    right: map_get($selectsSpacingIfIconLeft, $name);
  //  }
  //}

  .search {
    height: 20px;
    width: 20px;
    left: 20px;
  }

}

.dropWrapper {
  position: fixed;
  left: 0;
  height: 100%;
  width: 100%;
  top: 0;

  ul {
    margin: 0;
    display: block;
    position: fixed;
    max-height: 500px;
    overflow-y: auto;
    background-color: white;
    padding: 0;
    list-style: none;
    border-radius: 8px;

    &.list {
      //border: 1px solid $grey-300;
      box-shadow: 0 0 26px rgba(0, 0, 0, 0.05);
    }


    li {
      display: flex;
      position: relative;
      min-height: 36px;
      padding: 8px;
      cursor: pointer;

      .content {
        margin: auto 0;
        font-weight: 500;
        font-size: 1rem;
        line-height: 16px;
      }

      &:not(.disabled):hover {
        //background-color: $redMain;

        .content {
          //color: $white;
        }
      }

      &.disabled {
        .content {
          //color: $greyTextForm;
          pointer-events: none;
          cursor: pointer;
        }
      }

      &.multiple {

      }
    }
  }

  &:hover ~ .selectBox input {
    border-radius: 6px;
  }
}
