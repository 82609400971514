.modal {
  position: relative;
  background-color: white;
  border-radius: 12px;
  padding: 28px 24px 24px 24px;
  max-height: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;

  .staticContent {

  }

  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .content {
    overflow: auto;
    margin-bottom: 24px;
    margin-right: -12px;

    > div {
      padding-right: 12px;
    }
  }
}
