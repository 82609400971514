@import "src/common/styles/common";

.usernameinput {
  height: 50px;
  padding: 12px 24px;
  border: none;
  outline: none;
  border-radius: 8px;
  color: #059ff5;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 200px;
}

