.container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 2% 20px;
  align-items: center;
  justify-content: space-between;

  .title {
  }

  .contentWrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    position: relative;

    .content {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 50px;
    align-items: center;
  }
}
