@import "wrappers";

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

* {
  font-family: 'lato', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}

::-webkit-scrollbar-track {
  //background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: cornflowerblue;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
