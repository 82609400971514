.timer {
  background-color: white;
  border-radius: 100%;
  left: 10px;
  top: 10px;
  position: fixed;
  height: 60px;
  width: 60px;
  text-align: center;
  z-index: 1;
  display: flex;
  opacity: 0.6;
  pointer-events: none;

  .number {
    color: white;
    margin: auto;
    font-size: 24px;
    line-height: 40px;

    &.green {
      color: green;
    }

    &.yellow {
      color: darkorange;
    }

    &.red {
      color: darkred;
    }
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    transform: rotateY(-180deg) rotateZ(-90deg);

    circle {
      stroke-dasharray: 160px;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-width: 4px;
      stroke: green;
      fill: none;
      animation: countdown 60s linear forwards;
      transition: 0.3s stroke ease;

      &.green {
        stroke: green;
      }

      &.yellow {
        stroke: darkorange;
      }

      &.red {
        stroke: darkred;
      }

    }
  }
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 160px;
  }
}
