@import "src/common/styles/common";

.wrapper {
  overflow: hidden;
  position: relative;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  background: rgb(139, 196, 228);
  background: linear-gradient(90deg, rgba(139, 196, 228, 1) 0%, rgba(170, 218, 185, 1) 24%, rgba(202, 233, 211, 1) 49%, rgba(210, 241, 179, 1) 77%, rgba(194, 236, 152, 1) 100%);

  .backImage {
    position: relative;
    height: 100%;
    width: fit-content;
    width: -moz-max-content; /* Firefox/Gecko */
    width: max-content;

    img {
      height: 100%;
      pointer-events: none;
    }
  }

}
