@import "src/common/styles/common";

.button {
  height: 50px;
  padding: 12px 24px;
  border: none;
  outline: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  @include transition(0.3s opacity ease);

  &.primary {
    background-color: #4966a8;
  }

  &:active, &:hover {
    opacity: 0.8;
  }
}

.buttonWrapper {
  cursor: pointer;
  display: flex;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
