@import "src/common/styles/common";

.container {
  @include user-select-none;
  box-shadow: 0 0 7px 3px #000000;
  background: rgb(139, 196, 228);
  background: linear-gradient(90deg, rgba(139, 196, 228, 1) 0%, rgba(170, 218, 185, 1) 24%, rgba(202, 233, 211, 1) 49%, rgba(210, 241, 179, 1) 77%, rgba(194, 236, 152, 1) 100%);
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
  padding: 8px 4px;
  box-sizing: content-box;

  img {
    display: flex;
    transition: 0.3s ease;
    flex-shrink: 1;
    flex-grow: 0;

    &.selected {
      box-shadow: inset 0 0 0 2px #059ff5;
      background-color: #63b24694;
      border-radius: 8px;
    }
  }

  &.landscape {
    flex-direction: column;
    height: 100%;
    width: 100px;
    min-width: 100px;

    img {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.portrait {
    flex-direction: row;
    width: 100%;
    min-height: 100px;
    height: 100px;

    img {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

}
