@import "src/common/styles/common";

.textField {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;

  &.isAutoFill input,
  input {
    width: 100%;
    background: white;
    height: 100%;
    font-weight: 500;
    order: 2;
    border: 1px solid #4966a8;
    border-radius: 8px;

    &::placeholder {
      color: grey;
    }
  }

  label {
    font-weight: 700;
    color: #4966a8;
    @include transition(transform 0.3s ease, font-size 0.3s ease);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
    order: 1;
    margin-bottom: 8px;
    @include user-select-none;

    &.top {
    }
  }

  &.iconLeft {
  }

  &.iconRight {
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    * {
      pointer-events: none;
    }
  }

  min-width: 100px;

  input {
    min-height: 50px;
    height: 50px;
    padding: 12px;
  }

  label {
    font-size: 14px;
    margin-bottom: 4px;
  }
}

